/**
 * @constant API_HOSTNAME
 * Replace with your Google AppScript Link
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxdBSylU3Q1iw39P-8DVTcbX5tjt2lWbr_DJu9LrWPA0dua3Mpb2iHk5hyRqc9Cq75x/exec`;

/**
 * @constant RSVP_STATUS
 * DO NOT CHANGES!
 */
export const RSVP_STATUS = {
  attend: 'ATTEND',
  notAttend: 'NOT_ATTEND',
  sent: 'SENT',
  opened: 'OPENED',
  waiting: 'WAITING',
};

/**
 * @constant ACTION_POST_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_POST_TYPE = {
  insertGuest: 'insert_guest',
  insertWish: 'insert_wish',
  insertGiftConfirmation: 'insert_gift_confirmation',
  updateRsvp: 'update_rsvp',
};

/**
 * @constant ACTION_GET_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_GET_TYPE = {
  read: 'read',
  readQrCode: 'read_qrcode',
};

/**
 * @constant API_TABLE_TYPE
 * DO NOT CHANGES!
 */
export const API_TABLE_TYPE = {
  wishes: 'WISHES',
  rsvp: 'RSVP',
  giftConfirmation: 'GIFT_CONFIRMATION',
};

export const API_ATTEND_INFO = [
  {
    title: 'Holy Matrimony',
    value: 'Holy Matrimony',
    shift: '1',
  },
  {
    title: 'Reception',
    value: 'Reception',
    shift: '1, 2',
  },
  {
    title: 'Holy Matrimony and Reception',
    value: 'Holy Matrimony and Reception',
    shift: '1',
  },
];
